<script lang="ts">
	import { onMount } from 'svelte'
	import type { ApiOptions, TicketNetwork } from '~/lib/types'
	import {
		city,
		// geoLoading,
		lat,
		long,
		state,
	} from '~/stores/geo'
	import { Events } from '../lib/tn'
	import {
		formatDate,
		generateLink,
		getGeoFilter,
		getWeekday,
	} from '../lib/utils'
	import Spinner from './Spinner.svelte'
	import EmptyState from '~/components/EmptyState.svelte'
	import { Icon } from '@steeze-ui/svelte-icon'
	import { ChevronRight } from '@steeze-ui/heroicons'
	import Select from './controls/Select.svelte'

	export let geo = false
	export let performerId = null
	export let venueId = null
	export let events: TicketNetwork.Result[] = []
	export let title = 'Events'
	export let sortBy: 'date' | 'tickets' | 'popularity' | null = 'date'
	export let search = ''

	let miles = '50'
	let loading = false
	let quickFilterString = ''

	let apiOptions: ApiOptions = {
		salesRankOptions: JSON.stringify({
			interval: 'week',
			metric: 'dollarValue',
		}),
		// includeParking: true
		// sort: ['_metadata/ticketCount'],
		// filter: `date/date ge ${formatISO(startOfToday(), { representation: 'date' })}`
	}

	// $: console.log($lat, $long, 'latlong in svelte')

	$: if (($lat && long && miles) || !geo) fetchData()
	$: sortedEvents = quickFilterString
		? events.filter((event) =>
				event.text?.name
					.toLowerCase()
					.includes(quickFilterString.toLowerCase()),
		  )
		: events.map((i) => ({ ...i, popularity: priority(i) }))

	onMount(async () => {
		// fetchData()
	})

	async function fetchData() {
		loading = true
		console.log('FETCH')
		if (geo) {
			// console.log('current lat from svelte', $lat, $long)
			apiOptions.geoFilter = getGeoFilter(Number(miles))
		}

		if (performerId) {
			apiOptions.performerFilter = 'id eq ' + performerId
			apiOptions.perPage = 500
		} else if (venueId) {
			apiOptions.filter = 'venue/id eq ' + venueId
			apiOptions.perPage = 500
		}

		let tempEvents: TicketNetwork.Result[] = []

		// console.log(apiOptions)
		if (search) {
			tempEvents = await Events.search({
				q: search,
				...apiOptions,
				perPage: 500,
			})
		} else {
			tempEvents = await Events.getMany(apiOptions)
		}
		// events = tempEvents

		loading = false

		if (sortBy === 'tickets') {
			events = tempEvents.sort((a, b) => {
				return a._metadata?.ticketCount > b._metadata?.ticketCount ? -1 : 1
			})
		} else if (sortBy === 'popularity') {
			events = tempEvents.sort((a, b) => {
				return priority(a) > priority(b) ? -1 : 1
			})
			// events = tempEvents.sort((a, b) => {
			// 	return a.salesRank > b.salesRank ? -1 : 1
			// })
		} else {
			events = tempEvents
		}
	}

	function priority(event: TicketNetwork.Result) {
		try {
			let numTickets = event._metadata?.ticketCount ?? 0
			let lowestPrice =
				event.pricingInfo?.averagePrice?.value ??
				event.pricingInfo?.lowPrice?.value ??
				0

			// console.log(event.text.name, numTickets * lowestPrice, {
			// 	numTickets,
			// 	lowestPrice,
			// })
			return numTickets * lowestPrice
		} catch (e) {
			console.error(e)
			return 0
		}
	}

	$: if ($city && $state && title === 'Events near you') {
		title = `Events near ${$city}, ${$state}`
	}
</script>

<div class="mx-auto w-full max-w-6xl">
	<div class="flex items-center justify-between px-6">
		<h3 class="text-md my-3 font-semibold text-gray-800 sm:text-xl">
			{title}
			<!-- {$geoLoading}
			{#if $geoLoading}
				Loading...
			{:else}
				<button on:click={() => window.reload(true)}>Refresh</button>
			{/if} -->
		</h3>
		<!-- {#if geo}
			<Select bind:value={miles} />
		{/if} -->
		<!-- <input
			type="text"
			name="quickFilter"
			id="quickFilter"
			bind:value={quickFilterString}
			class="block w-64 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
			placeholder="Quick Filter"
		/> -->
	</div>
	{#if loading && events?.length === 0}
		<Spinner />
	{:else if events?.length}
		<ul
			class="mb-3 divide-y divide-gray-200 overflow-hidden border-b border-t bg-white px-4 sm:rounded-md sm:border sm:shadow sm:shadow-gray-200"
		>
			{#each sortedEvents as event (event.id)}
				{@const {
					venue,
					date,
					text,
					id,
					_metadata,
					pricingInfo,
					salesRank,
					eventPriority,
					priority,
				} = event}
				<li>
					<!-- {JSON.stringify(event)} -->
					<!-- <a
						href={generateLink.event(text?.name, id)}
						data-sveltekit-preload-code
						data-sveltekit-preload-data
					> -->
					<a href={generateLink.event(text?.name, id)}>
						<!-- <a href="/events/{event.id}"> -->
						<div class="flex items-center py-5 sm:px-6">
							<div class="flex min-w-0 flex-1 items-center">
								<div
									class="hidden flex-shrink-0 basis-36 self-start pl-4 sm:block md:basis-48 lg:basis-64"
								>
									<h3 class="text-sm font-medium text-gray-900">
										<!-- {JSON.stringify(date)} -->
										{formatDate(date)}
									</h3>
									<p class="truncate text-xs text-gray-500">
										{getWeekday(date.weekday)} - {date.text?.time}
									</p>
								</div>
								<div
									class="block flex-shrink-0 basis-20 self-center pr-4 text-center sm:hidden"
								>
									<h3 class="text-xs font-semibold text-gray-900">
										{formatDate(date)}
									</h3>
									<p class="truncate text-xs text-gray-500">
										{getWeekday(date.weekday, true)}
									</p>
									<p class="truncate text-xs text-gray-500">
										{date.text?.time}
									</p>
								</div>
								<div class="flex-1">
									<h3 class="sm:text-md text-sm font-medium text-gray-900">
										{text?.name}
									</h3>
									<p class="pr-2 text-xs text-gray-500 sm:text-sm">
										{venue?.text?.name}
									</p>
									{#if pricingInfo?.lowPrice?.text?.formatted}
										<p class="text-xs font-medium text-blue-600 sm:hidden">
											{pricingInfo?.lowPrice?.text?.formatted}
										</p>
									{/if}
								</div>
								<div class="hidden items-center self-end pr-4 sm:flex">
									<button
										class="inline-flex w-32 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
									>
										{pricingInfo?.lowPrice?.text?.formatted
											? 'From ' + pricingInfo?.lowPrice?.text?.formatted
											: _metadata?.ticketCount > 0
											? _metadata.ticketCount + ' Tickets'
											: 'Tickets'}
									</button>
								</div>

								<div class="block sm:hidden">
									<Icon
										src={ChevronRight}
										theme="mini"
										class="h-5 w-5 text-gray-400"
									/>
								</div>
							</div>
						</div>
					</a>
				</li>
				<!-- <div class="flex items-center space-x-3">
					{salesRank} Sales Rank
					{eventPriority} Event Priority
					{_metadata.ticketCount} Tickets
				</div> -->
			{/each}
		</ul>
	{:else}
		<EmptyState />
	{/if}
</div>
