<script>
	import { Calendar, Icon } from 'svelte-hero-icons'
</script>

<div class="text-center">
	<Icon src={Calendar} class="mx-auto h-12 w-12 text-gray-400" />
	<h3 class="mt-2 text-sm font-medium text-gray-900">No events here</h3>
	<p class="mt-1 text-sm text-gray-500">Try again later.</p>
	<!-- <div class="mt-6">
		<button
			type="button"
			class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
		>
			<Icon src={Plus} theme="mini" class="-ml-1 mr-2 h-5 w-5" />
			New Project
		</button>
	</div> -->
</div>
